import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { FormControl, FormGroup, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { DictionaryCodeValueResponse, ShopResponse } from '@models/generated/api';
import { ActivatedRoute } from '@angular/router';
import { ShopService } from '@services/shop.service';
import { Dialog, DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ToastService } from '@services/toast.service';
import { DictionariesService } from '@services/dictionaries.service';
import { errors, noWhitespaceValidator } from '@utils/validations';

@Component({
	selector: 'tg-payment-tinkoff',
	templateUrl: './payment-tinkoff.component.html',
	styleUrls: ['./payment-tinkoff.component.sass', '../shop-settings-payment.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentTinkoffComponent {
	shopId: string;
	@ViewChild('merchantKey') merchantKey: ElementRef<HTMLInputElement>;

	vatCodes: DictionaryCodeValueResponse[];
	taxationCodes: any[];
	ffdCodes: any[];

	form = new FormGroup({
		merchantKey: new FormControl('', [noWhitespaceValidator, Validators.maxLength(100)]),
		password: new FormControl('', [noWhitespaceValidator, Validators.maxLength(100)]),
		ffdVersion: new FormControl('', []),
		taxation: new FormControl('', []),
		vatCode: new FormControl('', []),
	});

	constructor(
		private route: ActivatedRoute,
		public shopService: ShopService,
		private dictionariesService: DictionariesService,
		private cdr: ChangeDetectorRef,
		@Inject(DIALOG_DATA) public dialogData: any,
		public dialogRef: DialogRef,
	) {
		Promise.all([
			this.dictionariesService.getVatCodes().toPromise(),
			this.dictionariesService.getTaxationCodes().toPromise(),
			this.dictionariesService.getFFDCodes().toPromise(),
		]).then(([vatCodes, taxationCodes, ffdCodes]) => {
			this.vatCodes = vatCodes;
			this.taxationCodes = taxationCodes;
			this.ffdCodes = ffdCodes;

			if (this.dialogData) {
				this.shopId = this.dialogData.shopId;
				if (this.dialogData.formValue) {
					this.form.patchValue(this.dialogData.formValue, { emitEvent: false });
					this.cdr.detectChanges();
				}
			}
		});
	}

	errors(form: UntypedFormGroup, field: string): ValidationErrors | null | undefined {
		return errors(form, field);
	}

	submit(): void {
		if (!this.form.valid) {
			this.form.markAllAsTouched();
			return;
		}

		const value = this.form.getRawValue();

		this.dialogRef.close(value);
	}

	cancel(): void {
		this.dialogRef.close(null);
	}
}
