import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DestroyService } from '@services/destroy.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductService } from '@services/product.service';
import { ProductResponse, ShopCreateRequest } from '@models/generated/api';
import { BehaviorSubject, filter, switchMap, takeUntil } from 'rxjs';
import { Clipboard } from '@angular/cdk/clipboard';
import { ToastService } from '@services/toast.service';
import { Dialog } from '@angular/cdk/dialog';
import { ProductDeleteComponent } from '@components/modals/product-delete/product-delete.component';
import { LoadingService } from '@services/loading.service';
import { ShopService } from '@services/shop.service';
import productType = ShopCreateRequest.productType;

@Component({
	selector: 'tg-product-detail',
	templateUrl: './product-detail.component.html',
	styleUrls: ['./product-detail.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [DestroyService, LoadingService],
})
export class ProductDetailComponent {
	columns;

	shopId: string | null = null;
	productId: string | null = null;

	productResponse$ = new BehaviorSubject<ProductResponse | null>(null);
	positions$ = new BehaviorSubject([]);

	activeColor: string | null;
	activePhoto: string | null;

	isLimit: boolean = false;
	isReadMore: boolean = true;
	descLimit: number = 1000;

	moneySign: string;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private destroy$: DestroyService,
		private productService: ProductService,
		private clipboard: Clipboard,
		private toast: ToastService,
		private dialog: Dialog,
		public loading$: LoadingService,
		public shopService: ShopService,
	) {
		this.loading$.next(true);
		this.shopId = this.route.snapshot.paramMap.get('id');
		this.productId = this.route.snapshot.paramMap.get('productId');

		this.productService.getProductData(this.shopId, this.productId).subscribe({
			next: data => {
				this.productResponse$.next(data);

				if (!!data.digitalGoods) {
					this.activePhoto = this.activePhoto = data.digitalGoods.photos[0]?.file.id;
					this.positions$.next([data.digitalGoods]);
					this.loading$.next(false);
					return;
				}
				this.activeColor = data.vendorCodes[0].color.id;
				this.activePhoto = data.vendorCodes[0].photos[0]?.file.id;

				this.isLimit = data.fullDescription.length > this.descLimit;

				const positions = [];
				data.vendorCodes.map(color => {
					color.positions.map(position => {
						positions.push({
							...position,
							...color,
						});
					});
				});
				this.positions$.next(positions);
				this.loading$.next(false);
			},
		});

		if (!shopService.shopMoneySign.value) {
			this.shopService.getShop(this.shopId).subscribe({
				next: res => {
					this.shopService.setMoneySign(res);
					this.shopService.shopInfo.next(res);
					this.columns = ['discount', 'price', 'vendor_code', 'amount'];
					if (res.productType === productType.GOODS) {
						this.columns = ['color', 'size', 'discount', 'price', 'vendor_code', 'amount'];
					}
				},
			});
		} else {
			this.columns = ['discount', 'price', 'vendor_code', 'amount'];
			if (this.shopService.shopInfo.value.productType === productType.GOODS) {
				this.columns = ['color', 'size', 'discount', 'price', 'vendor_code', 'amount'];
			}
		}
	}

	getCurrentPhotos() {
		return this.productResponse$.value.digitalGoods
			? this.productResponse$.value.digitalGoods.photos
			: this.productResponse$.value.vendorCodes.find(color => color.color.id === this.activeColor)?.photos;
	}

	getPhotoPreviewItems() {
		if (this.getCurrentPhotos().length < 4) return this.getCurrentPhotos();
		else {
			switch (this.getCurrentPhotos().length) {
				case this.getActivePhotoIndex() + 3:
					return this.getCurrentPhotos().slice(this.getActivePhotoIndex(), this.getActivePhotoIndex() + 3);
				case this.getActivePhotoIndex() + 2:
					return this.getCurrentPhotos().slice(
						this.getActivePhotoIndex() - 1,
						this.getActivePhotoIndex() + 2,
					);
				case this.getActivePhotoIndex() + 1:
					return this.getCurrentPhotos().slice(
						this.getActivePhotoIndex() - 2,
						this.getActivePhotoIndex() + 1,
					);
				default:
					return this.getCurrentPhotos().slice(this.getActivePhotoIndex(), this.getActivePhotoIndex() + 3);
			}
		}
	}

	deleteProduct() {
		this.dialog
			.open(ProductDeleteComponent)
			.closed.pipe(
				takeUntil(this.destroy$),
				filter(Boolean),
				switchMap(() => this.productService.deleteProduct(this.shopId, this.productId)),
			)
			.subscribe({
				next: () => {
					this.router.navigate(['/shop/' + this.shopId]);
					this.toast.success('Товар успешно удалён');
				},
				error: err => {
					this.toast.error(err?.error?.message);
				},
			});
	}

	editProduct() {
		this.router.navigate(['/shop/' + this.shopId + '/products/' + this.productId + '/edit-product']);
	}

	setActiveColor(id: string): void {
		this.activeColor = id;
		this.activePhoto = this.getCurrentPhotos()[0].file.id;
	}

	getActivePhotoIndex(): number {
		return this.getCurrentPhotos().findIndex(photo => photo.file.id === this.activePhoto);
	}

	setActivePhoto(id: string): void {
		this.activePhoto = id;
	}

	copyLink(): void {
		this.clipboard.copy(this.productResponse$.value.productLink);
		this.toast.success('Ссылка на товар скопирована');
	}

	openProduct() {
		this.router.navigate(['/shop/' + this.shopId + '/products/create-product'], {
			queryParams: { product: this.productId },
		});
	}
}
